<template>
    <div>
      <b-container class="mt-5 mb-5">
        <h1 class="fs-4 font-weight-bold mb-0">Profiel</h1>
        <p class="text-muted">Optimaliseer je Tixgo profiel door de gegevens van je organisatie te bewerken.</p>
  
        <ValidationObserver ref="form" slim>
          <b-card class="mb-3">

            <h3 class="fs-5">Linken</h3>
            <p class="text-muted mb-2">Wil je direct linken naar je profiel? Gebruik dan de onderstaande link.</p>
            <b-form-input v-model="getLink" readonly />

            <hr/>

            <h3 class="fs-5">Introductie</h3>
            <ValidationProvider rules="required|max:500" v-slot="{ errors }">
              <div class="mb-3">
                <label class="required">Omschrijving</label>
                <VueTrix v-model="form.description" />
                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>

            <hr/>
            
            <b-row>
                <b-col md="6">
                    <div class="mb-3">
                        <label>Header</label>
                        <p class="text-muted">Optimaal formaat: 1920x1080 / 4:3 ratio (max. 5MB)</p>
                        <DropFile v-model="form.header_image" accept="image/*" @change="fileChange" @delete="fileChange" />
                    </div>
                </b-col>
                <b-col md="6">
                    <div class="mb-3">
                        <label>Logo</label>
                        <p class="text-muted">Optimaal formaat: 1024x1024 / 1:1 ratio (max. 5MB)</p>
                        <DropFile v-model="form.header_logo" accept="image/*" @change="fileChange"  @delete="fileChange" />
                    </div>
                </b-col>
            </b-row>
  
          </b-card>
        </ValidationObserver>

        <ValidationObserver ref="form" slim>
          <b-card class="mb-3">

            <h3 class="fs-5">Socials</h3>

            <b-row cols="1" cols-md="2">
              <b-col>
                <ValidationProvider v-slot="{ errors }">
                    <div class="mb-3">
                      <label>Website</label>
                      <b-input-group prepend="https://">
                          <b-form-input v-model="form.website" placeholder="www.website.com" />
                      </b-input-group>
                      <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
              </b-col>
              <b-col>
                <ValidationProvider v-slot="{ errors }">
                    <div class="mb-3">
                      <label>Facebook</label>
                      <b-input-group prepend="www.facebook.com/">
                          <b-form-input v-model="form.social_facebook" placeholder="username" />
                      </b-input-group>
                      <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
              </b-col>
              <b-col>
                <ValidationProvider v-slot="{ errors }">
                    <div class="mb-3">
                      <label>Instagram</label>
                      <b-input-group prepend="www.instagram.com/">
                          <b-form-input v-model="form.social_instagram"  placeholder="username" />
                      </b-input-group>
                      <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
              </b-col>
              <b-col>
                <ValidationProvider v-slot="{ errors }">
                    <div class="mb-3">
                    <label>Twitter</label>
                    <b-input-group prepend="www.twitter.com/">
                        <b-form-input v-model="form.social_twitter"  placeholder="username" />
                    </b-input-group>
                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
              </b-col>
              <b-col>
                <ValidationProvider v-slot="{ errors }">
                    <div class="mb-3">
                    <label>LinkedIn</label>
                    <b-input-group prepend="www.linkedin.com/">
                        <b-form-input v-model="form.social_linkedin"  placeholder="username" />
                    </b-input-group>
                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
              </b-col>
              <b-col>
                <ValidationProvider v-slot="{ errors }">
                    <div class="mb-3">
                    <label>TikTok</label>
                    <b-input-group prepend="www.tiktok.com/">
                        <b-form-input v-model="form.social_tiktok"  placeholder="username" />
                    </b-input-group>
                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
              </b-col>
            </b-row>
          </b-card>
        </ValidationObserver>
  
        <div class="clearfix">
          <b-button @click="saveOrganisation" variant="primary" class="float-right">
            Opslaan
            <font-awesome-icon icon="fa-solid fa-chevron-right" />
          </b-button>
        </div>
  
      </b-container>
    </div>
  </template>
  
  <script>
      import { mapActions } from 'pinia'
      import { useShopStore } from '@/stores/shop'
      import { ValidationObserver, ValidationProvider } from 'vee-validate';
      import VueTrix from "vue-trix";
      import DropFile from "@/components/DropFile.vue";
  
      export default {
          metaInfo: {
              title: 'Profiel',
          },
          components: {
            ValidationObserver,
            ValidationProvider,
            VueTrix,
            DropFile,
          },
          data() {
              return {
                form: {},
              }
          },
          computed: {
            getLink: function() {
                return 'https://www.tixgo.nl/host/' + this.form.slug + '-' + this.form.slug_hash;
            }
          },
          methods: {
            ...mapActions(useShopStore, ['setLoading']),
            fileChange: function() {
                this.$axios.put("https://api.tixgo.nl/webshops/update", this.form)
                    .then( response => {
                        response;
                });
            },
            getOrganisation: function() {
              this.setLoading(true);
              this.$axios.get("https://api.tixgo.nl/organisations/show")
                .then( response => {
                  this.form = response.data;
                  this.setLoading(false);
                })
              ;
            },
            async saveOrganisation() {
              
              let check = await this.$refs['form'].validate();
  
              if (!check) {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth"
                });
                
                return;
              }
  
              this.$axios.put("https://api.tixgo.nl/organisations/update", this.form)
                .then( response => {
                    response;
                  this.$router.push('/manage/organisation')
                })
              ;
            }
          },
          created() {
            this.getOrganisation();
          }
      }
  </script>