<template>
    <div>
        <label
        class="dropzone-container"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
        v-if="!preview"
        >
            <div class="file-label">
                <div v-if="isDragging">Loslaten om te uploaden</div>
                <div v-else>Drag & Drop je bestanden of <u>blader</u>.</div>
            </div>
            <input type="file" ref="file" class="hidden-input" @change="onFileChange" :accept="accept" />
        </label>
        <div
        class="dropzone-container"
        v-if="preview"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
        >
            <div class="preview-container">
                <div class="preview-info">
                    <b-button @click="remove" variant="outline-primary">X</b-button>
                </div>
                <div class="preview-img" :style="{ backgroundImage:`url(` + preview + `)` }" />
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    prop: ['value','accept'],
    data () {
        return {
            file:null,
            preview:null,
            accept:null,
            content:null,
            isDragging:false,
        }
    },
    methods:{
        handleInput () {
            this.$emit('input', this.content)
        },
        onFileChange:function (event) {
            const file = event.target.files[0]

            if (!file) {
                return false
            }

            if (!file.type.match('image.*')) {
                return false
            }

            const formData = new FormData();
            formData.append('file', file);

            const headers = {
                'Content-Type':'multipart/form-data',
                'Authorization':`Bearer ${localStorage.getItem('token')}`,
            };

            this.$axios.post('https://api.tixgo.nl/files', formData, { headers }).then(
                response => {
                    this.content = response.data;
                    this.preview = 'https://api.tixgo.nl/files/' + response.data;
                    this.handleInput();
                    this.$emit('change');
                }
            );
        },
        remove() {
            const headers = {
                'Content-Type':'multipart/form-data',
                'Authorization':`Bearer ${localStorage.getItem('token')}`,
            };

            this.$axios.delete('https://api.tixgo.nl/files/' + this.file, { headers }).then(
                response => {
                    response;
                    this.content = null;
                    this.file = null;
                    this.preview = null;
                    this.handleInput();
                    this.$emit('delete');
                }
            );
        },
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {
            e.preventDefault();
            this.$refs.file.files = e.dataTransfer.files;
            this.isDragging = false;

            // Trigger change event
            this.$refs.file.dispatchEvent(new Event('change'));
        },
    },
    watch:{
        '$attrs.value':function(newVal) {
            if (newVal) {
                this.preview = 'https://api.tixgo.nl/files/' + newVal;
            } else {
                this.preview = null;
            }
            this.file = newVal;
        },
        '$attrs.accept':function(newVal) {
            this.accept = newVal;
        }
    }
};
</script>

<style scoped>
    .dropzone-container {
        padding:15px;
        background:#F2F5F7;
        border:1px solid #e2e8f0;
        border-radius:10px;
        position:relative;
        overflow:hidden;
        cursor:pointer;
        width:100%;
        max-width:500px;
    }
    .preview-container{
        min-height:200px;
    }
    .preview-img{
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-size:contain;
        background-color:#222222;
        background-repeat:no-repeat;
        background-position:50% 50%;
        z-index:0;
    }
    .preview-info{
        position:absolute;
        z-index:1;
        top:10px;
        left:10px;
        right:10px;
        text-align:right;
    }
    .hidden-input {
        opacity:0;
        overflow:hidden;
        position:absolute;
        width:1px;
        height:1px;
    }
    .file-label {
        display:block;
        color:#62656b;
        text-align:center;
    }
</style>